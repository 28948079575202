import {m, Button, Input} from '../../n2/components/base.js'
import {Frame} from './layout.js'

import Login from '../state/Login.js'

const onLogin = () => Login.send().then(() => window.location.reload())
const onKeyUp = (ev) => ev.code === 'Enter' && onLogin()
const sizeClass = 'w-80 py-2 px-3'

export default {
    view: (vnode) => m(Frame, {
        subTitle: 'Anmeldung' ,
        backgroundSrc: '/assets/background-login-high.png'
    }, [
        m('.flex.flex-col', [
            m('.self-center.flex.flex-col.absolute.top-1/4.space-y-5', [
                Login.message && m('h2.h-10.text-center.text-brand1.font-bold.mb-4.bg-white.rounded', Login.message),
                m(Input, {
                    class: sizeClass,
                    type: 'email',
                    required: true,
                    onChange: (value) => Login.email = value,
                    onkeyup: onKeyUp,
                }),
                m(Input, {
                    class: sizeClass,
                    type: 'password',
                    required: true,
                    onChange: (value) => Login.password = value,
                    onkeyup: onKeyUp,
                }),
                m(Button, {
                    class: sizeClass,
                    classes: '.mt-6',
                    disabled: !Login.canSend(),
                    onclick: onLogin
                }, [
                    'Log in' || Login.message
                ])
            ])
        ]),
    ])
}
