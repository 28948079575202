import m from '../../vendor/mithril.js'

const Login = {
    email: '',
    password: '',

    state: 'input',

    canSend: () => Login.email.length >= 6 && Login.password.length > 6,

    send: () => new Promise((resolve, reject) => {
        if (Login.canSend()) {
            Login.state = 'waiting'
            m.request({
                method: 'POST',
                url: 'auth/login',
                body: {
                    email: Login.email,
                    password: Login.password
                }
            })
            .then((result) => {
                Login.state = result.status === 'ok' ? 'success' : 'input'
                Login.message = result.message
                resolve()
            })
            .catch(reject)
        } else {
            reject()
        }
    })
}

export default Login